<template>
  <div class="">
    <div class="recommendList" v-for="item in searchsList" :key="item.id" @click="toRecommendList(item.vod_name)">
      <div class="listLeft">
        <div><van-icon name="search" /></div>
        <div class="brief">{{ item.vod_name }}</div>
      </div>
      <div class="listF"><img src="../../assets/路径887.png" alt=""></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: '',
  components: {},
  data() {
    return {
      searchsList: null,//搜索建议信息
    }
  },
  // 计算属性
  computed: {
    ...mapGetters([
      'getInfo'
    ])
  },
  watch: {

  },
  mounted() {
    this.getInfos()
  },
  methods: {
    getInfos() {
      this.$store.watch(
        (state) => state.searchInfo,
        (newVal) => {
          this.searchsList = newVal;
        }
      )
    },
    // 去播放页面
    toRecommendList(vod_name) {
      this.$store.dispatch('getSearchValue', vod_name)
      this.$router.push({
        path: '/search/recommendList',
        query: {
          kw: vod_name
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.recommendList {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #292929;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--title-color);
  opacity: 0.8;
}

.listLeft {
  display: flex;
}

.listLeft>div:first-child {
  margin-right: 10px;
}
</style>
